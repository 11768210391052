import { createSlice } from '@reduxjs/toolkit';

export interface GlobalState {
  is_sidebar_open: boolean;
}

const initialState: GlobalState = {
  is_sidebar_open: false,
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    updateSidebar: state => {
      state.is_sidebar_open = !state.is_sidebar_open;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSidebar } = globalSlice.actions;

export default globalSlice.reducer;
